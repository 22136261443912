img.expertise-image{
    height: 85px;
    min-width: 80px;
    max-width: 90px;
    object-fit: contain;
}

.view-button{
    border-style: none;
    border: 1px solid #255F85;
    color: #255F85;
    border-radius: 8px;
    width: 140px;
    height: 54px;
    font-family: Inter;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;
    margin-top: 20px;
}