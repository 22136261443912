@keyframes ldio-mnixogz3m2j {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-mnixogz3m2j div {
    position: absolute;
    width: 30px;
    height: 30px;
    border: 5px solid #5ed0e6;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-mnixogz3m2j div {
    animation: ldio-mnixogz3m2j 1s linear infinite;
    top: 25px;
    left: 25px
  }
  .loadingio-spinner-rolling-t4pwvx0hj2e {
    width: 50px;
    height: 50px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-mnixogz3m2j {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-mnixogz3m2j div { box-sizing: content-box; }