footer{
   margin:0%;
   right:0%;
   background: white;
   color: black;
   z-index: 117;
   position: relative;
   font-family: Inter;

   .footer-social{
      margin-left: -15px;

      @media (max-width: 992px) {
         margin-left: 0px;
      }

      @media (max-width: 768px) {
         margin-left: 15px;
      }
   }
   // background: linear-gradient(4deg, $primary 10%, #1a3754bf 100%);

   .footer-info {
      width: 50%;

      @media (max-width: 768px) {
         width: 100%;
      }
   }
}