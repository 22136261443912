.product-page-main-container{
  font-family: "Inter";

  width: 100%;
  height: 100%;


  .third-section{
    .third-section-heading{
      font-family: 'Open Sauce One' !important;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.04em;
      text-align: center;
      width: 705px;
      margin-top: 64px;
      color: #101828;
    }
    .product-card{
      border: 1px solid #EFEFEF;
      padding: 20px;
      border-radius: 16px;
      max-width: 469px;
      box-shadow: 0px 4px 4px 0px #00000040;

      @media screen and (max-width: 1024px) {
       min-width:  100%;
      }


    }

    .fs-32px{
      font-size: 32px;
    }
    .third-section-container{
      padding: 0  150px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0 20px;
      }


    }

    .card-container{
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 48px;

      @media screen and (max-width: 1024px) {
        width: 100%
      }

    }

    .card-header{
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #255F85;
      margin-bottom: 8px;
    }

    .card-detail{
      font-weight: 400;
      font-size: 16px;
      color: #344054;
      line-height: 26px;
      letter-spacing: -0.01em;
      text-align: left;

    }
    .section-detail{
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.01em;
      text-align: center;
      color:#344054;
      width: 799px;
    }
  }

  .second-section{
    .second-section-heading{
      font-family: 'Open Sauce One' !important;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.04em;
      text-align: center;
      width: 705px;
      margin-top: 64px;
      color: #101828;
    }
    .first-section-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;


      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }

    }

    .second-section-image{
      width: 530px;
      height: 582px;
    }

    .fs-32px{
      font-size: 32px;
      width: 700px;
    }

    .central-bar{
      height: 5px;
      width: 109px;
      background:#255F85 ;
      border-radius: 8px;
      margin-bottom: 48px;
    }

    .third-section-image{
      width: 387px;
      height: 710px;
    }

    .product-card{
      border: 1px solid #EFEFEF;
      padding: 20px;
      border-radius: 16px;
      max-width: 584px;
      box-shadow: 0px 4px 4px 0px #00000040;
      @media screen and (max-width: 1024px) {
        min-width: 100%
      }

    }

    .card-container{
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 48px;

      @media screen and (max-width: 1024px) {
        width: 100%
      }

    }

    .card-header{
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #255F85;
      margin-bottom: 8px;
    }

    .card-detail{
      font-weight: 400;
      font-size: 16px;
      color: #344054;
      line-height: 26px;
      letter-spacing: -0.01em;
      text-align: left;
    }
  }

  .first-section{
    .first-section-heading{
      font-family: 'Open Sauce One' !important;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.04em;
      text-align: center;
      width: 705px;
      margin-top: 64px;
      color: #101828;
    }
    .first-section-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 120px;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0 20px;
      }

    }


    .fs-32px{
      font-size: 32px;
      width: 700px;
    }

    .central-bar{
      height: 5px;
      width: 109px;
      background:#255F85 ;
      border-radius: 8px;
      margin-bottom: 48px;
    }

    .first-section-image{
      width: 318px;
      height: 620px;
    }

    .product-card{
      border: 1px solid #EFEFEF;
      padding: 20px;
      border-radius: 16px;
      max-width: 584px;
      box-shadow: 0px 4px 4px 0px #00000040;
      @media screen and (max-width: 1024px) {
        min-width: 100%
      }

    }

    .card-container{
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 48px;
      @media screen and (max-width: 1024px) {
        width: 100%
      }

    }

    .card-header{
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #255F85;
      margin-bottom: 8px;
    }

    .card-detail{
      font-weight: 400;
      font-size: 16px;
      color: #344054;
      line-height: 26px;
      letter-spacing: -0.01em;
      text-align: left;
    }
  }

  .product-banner{
    background: #154A62;
    width: 100%;
    display: flex;
    padding-left: 150px;
    padding-right: 64px;
    height: 466px;


    justify-content: space-between;

    .banner-heading{
      font-family: 'Open Sauce One';
      font-size: 48px;
      font-weight: 700;
      line-height: 59.38px;
      letter-spacing: -0.02em;
      text-align: left;
      color: white;
      width: 673px;
    }
    .double-screen-container{
      position: relative;
      .double-image{
        position: absolute;
          width: 500px;
        height: 500px;
        right: -21px;
        bottom: -190px;
      }
    }
    .large-screen-banner-image-container{
      margin-right: 48px;
      position: relative;
      align-self: flex-end;
      display: flex;
      justify-content: flex-end;
      .img-1{
        position: absolute;
        bottom: 0;
        right: 59%;
      }


    }

    .image-container{
      display: flex;
      gap: 24px;
      .google-play{
        height: 54px;
        width: 182px;
      }

      .app-store{
        height: 54px;
        width: 181px;
      }
    }

  }

  .logicurv-product{
  display: none;}
  .product-container{
    .product-header{
      color: #273043;
      font-weight: 800;
      width: 561px;
      font-family: 'Open Sauce One';
      font-size: 51px;
    }


    .product-details{
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      color: #1D2939;
      width: 561px;
      margin-top: 40px;
      letter-spacing: -0.01em;
      text-align: left;


    }

    .banner-image-container-mobile{
      display: none;
    }

    .arrow-icons{
      width: 16px;
      height: 11px;
    }

    .banner-image-container-web{
      position: absolute;
      width: 526px;
      right: 5%;
      top: 30%;


      .banner-image-container-web-inner{
        position: relative;
        display: flex;
        justify-content: flex-start;
         .banner-image-web{
           width: 472px;
           height: 314px;
         }
      }

    }



  }

}








  .first-section,.second-section{
    font-family: "Open Sauce One";

    width: 100%;



  }

  .second-section{
    font-family: "Open Sauce One";
    width: 100%;
    background: #F9FAFB;
    height: 841px;

    @media screen and (max-width: 1024px) {
      height: 100%
    }

    .fs-32px{
      font-size: 32px;
      width: 700px;
    }

    .second-section-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }

    }

    .border-primary{
      border:1px solid  #E37429;
    }

    .product-card{
      border: 1px solid #EFEFEF;
      padding: 20px;
      border-radius: 16px;
      max-width: 448px;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

  }





@media screen and (max-width: 1024px) {
  .product-page-main-container{
    margin-top: 0;
    .logicurv-product{
      margin-top: 0;
      display: block;
      text-align: center;
      text-transform: uppercase;
      color: #255F85;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      letter-spacing: 0.04em;
    }

    .third-section{
      .third-section-image{
        width: 341px;
        height: 620px;
      }
      .section-detail{
        width: 90%;
      }
      .third-section-heading{
        width: 100%;
      }
    }


    .product-banner{
      padding: 75px 42px 32px 35px;
      display: flex;
      flex-direction: column;
      height: 100%;
       align-items: flex-start;

      .banner-heading{
        font-size: 28px;
        width: 100%;
      }
      .image-container{
         .google-play{
           width: 121px;
           height: 36px;
         }
          .app-store{
            height: 36px;
            width: 120px;
          }

      }
      .double-screen-container{
        display: flex;
        width: 100%;
        position: static;
        justify-content: center;
        align-items: center;
        .double-image{
          position: static;
          margin-top: 8px;
          height: 400px;
          width: 400px;
        }
      }
      .large-screen-banner-image-container{
        display: none;
      }
    }
    .product-container{
      .product-header{
        width:100%;
        text-align: center;
        font-size: 42px;
      }
      .product-details{
        width: 100%;
        text-align: center;
        font-size: 18px;
      }

      .link-container{
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .banner-image-container-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        .double-image{
          width: 400px !important;
          height: 400px !important;
        }
        .banner-image{
          width: 500px;
        }
      }
      .banner-image-container-web{
        display: none;
      }
    }

    .first-section{
      .first-section-image{
        width: 324px;
        height: 590px;
      }
    }

    .second-section{
      .second-section-image{
        width: 392px;
        height: 413px;
      }
    }


  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .product-page-main-container{
    .product-container{
      .banner-image-container-web{
        right: 0;
        .banner-image-container-web-inner{
          justify-content: flex-start;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .logicurv-product{
    margin-top: -178px!important;
  }
}


@media screen and (max-width: 767px) {
  .product-page-main-container {
    margin-top: 40px;
    .logicurv-product {
      margin-top: 40px;
    }
  }
  }


@media screen and (max-width: 391px) {

 .banner-image-container-mobile{
   .banner-image{
     width: 300px!important;
   }

   .double-screen-container{
     .double-image{
       height: 800px;
       width: 800px;
     }
   }
 }
}
