
.banner {
    position: relative;
    background: linear-gradient(to top right, rgba(71, 222, 255, 0.4) 0%, white 60%);
    // background-image: url('/images/backgrounds/banner.jpg');
    width: 100%;
    min-height: 800px;
    overflow: hidden;
    @include media-breakpoint-up(md){
        min-height: 99vh;
    }
    .app-wrapper{
        z-index: 2;
    }
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    h1{
        width: 85%;
        font-size: 76px;
        font-weight: bold;
        line-height: 88px;
        text-align: left;
        vertical-align: top;
        letter-spacing: -2%;
        font-family: 'Open Sauce One';

        @include media-breakpoint-down(md){
            font-size: 42px;
            line-height: 56px;
        }
    }
    .lead{
        max-width: 600px;
        z-index: 2;
        color: black;
        font-family: Inter;
        font-size: 20px;
        line-height: 32px;
        text-align: left;
        vertical-align: top;
        letter-spacing: -1%;
        margin-top: 20px;
        @include media-breakpoint-down(md){
            font-size: 18px;
            line-height: 30px;
        }
    }
    .banner-button{
        // max-width: 150px;
        width: max-content;
        // height: 54px;
        background-color: #255F85;
        border-style: none;
        border-radius: 8px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        padding: 12px 36px;
        margin-top: 10px;
        // gap: 8px;
        font-family: Inter;
        transition-duration: 0.3s;
    }
    .banner-button:hover{
        background-color: rgb(14, 73, 112);
    }
    .wave{
        position: absolute;
        height: 100%;
        right: 0%;
        bottom: -100px;
        z-index: -10;
    }
}
