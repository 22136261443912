.contact-detail{
    font-family: Inter;
    background: linear-gradient(to top right, rgb(84, 215, 243, 0.3) 0%, white 80%);
    
    // background: radial-gradient(rgb(84, 215, 243, 0.3) 0%, white 100%);

    .contact-container{
        width: 70%;
        margin-top: 100px;

        @media (max-width: 992px) {
            width: 85%;
        }
        
        @media (max-width: 768px) {
            width: 90%;
        }
    }

    p{
        font-family: 'Inter';
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #255F85;

    }

    .form-container{
        width: 55%;
        
        @media (max-width: 768px) {
            width: 90%;
        }

        @media (max-width: 576px) {
            width: 100%;
        }
    }

    .contact-page-form{
        padding: 32px;
        background: #FFFFFF;
        border-radius: 16px;
        gap: 22px;
        
        @media (max-width: 768px) {
            padding: 32px 20px;
        }

        label{
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            color: #1D2939;
            
            @media (max-width: 768px) {
                font-size: 16px;
                font-weight: 500;
            }
        }

        .form-label{
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            color: #667085;
        }

        a{
            font-weight: 600;
            color: #3b74ba;
        }

        input{
            height: 44px;
            background-color: white;
            border-radius: 8px;
            color: black;
            border: 1px solid #D0D5DD;
        }

        input::placeholder{
            color: #bbb;
        }

        select{
            &:invalid {
                color: gray;
              }
            
              // Styling for browsers which do support
              // styling select option elements directly
              [disabled] {
                color: gray;
              }
            
              option {
                color: black;
              }
        }
        

        textarea{
            resize: none;
        }
    }

    .form-submit {
        padding: 12px 30px;
        gap: 8px;
        background: #255F85;
        border-radius: 8px;
        color: white;
        font-weight: 600;
        font-size: 18px;
        border-style: none;
        
    }


    .contact-info{
        p {
            font-weight: 400;
            font-size: 18px;
            margin: 10px;
            margin-left: -2px;
        }
    }
    
    .notification p{
        color: #20c997;
        font-weight: 600;
        font-size: 14px;

    }

    .company-details{
        margin-top: 5px;
        margin-left: 20px;
        width: 35%;
        
        @media (max-width: 768px) {
            width: 90%;
        }

        @media (max-width: 576px) {
            width: 100%;
        }
        // width: 35%;

        h1{
            font-size: 28px;
            line-height: 34px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        p{
            text-align: left;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: #1D2939;
        }

        .contact-social{
            margin-top: 20px;
            margin-left: -15px;

            @media (max-width: 992px) {
                margin-left: 0px;
            }
        }
    }
}


.location {
    iframe {
        
        min-height: 400px;
        width: 100%;
        min-width: none;
        padding-bottom: 15px;
        margin:0;

        @include media-breakpoint-up(md){
            min-width: 500px;
        }
        @include media-breakpoint-up(lg){
            min-width: 600px;
        }
    }
}