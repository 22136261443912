.career-banner{
    .icon-right{
        margin-left: auto;
        font-size: 25px;
        line-height: 20px;
    }

    .card{
        max-width: 700px;
    }

    .career-icon{
        font-size: 25px;
        line-height: 25px;
        padding-right: 10px;
        margin-right: 10px;
        // border-right: 1px solid gray;
        background-color: rgb(238, 234, 234) ;
        // background-color: rgb(84, 215, 243, 0.5);
    }

    .banner{
        background: linear-gradient(to top right, rgb(84, 215, 243, 0.5) 0%, white 80%);
    }

    .career-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: Inter;

        h1{
            text-align: center;
            font-family: "Open Sauce One";
        }

        p{
            max-width: 70%;
            text-align: center;
        }
    }

    .career-button{
        background-color: #255F85;
        border-style: none;
        border-radius: 5px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 36px;
        margin-top: 10px;
        font-family: Inter;
        transition-duration: 0.3s;
    }
    .career-button:hover{
        background-color: rgb(14, 73, 112);
    }
    
    .values{

        .box1, .box2, .box3, .box4{
            // height: 526px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            border-radius: 16px;

            h2{
                font-family: "Open Sauce One";
                font-size: 32px;
                line-height: 40px;
                @media (max-width: 768px) {
                    font-size: 24px;
                }
            }

            p{
                font-family: Inter;
                font-size: 20px;
                line-height: 32px;
                @media (max-width: 768px) {
                    font-size: 20px;
                }
            }
        }

        .box1{
            background-color: #273043;
            color: white;
            width: 45%;
            padding: 104px 40px;
            // margin: 10px;

            @media (max-width: 992px) {
                width: 100%;
            }
        }

        .box2{
            width: 55%;
            @media (max-width: 992px) {
                width: 100%;
            }
        }

        .box3, .box4{
            // margin: 10px;
            padding: 48px 40px;
            color: #1D2939;
        }
    }

    .benefits{
        h2{
            font-family: "Open Sauce One";
            font-size: 28px;
            line-height: 34px;
        }

        p{
            font-family: Inter;
            font-size: 16px;
            line-height: 26px;
            color: #344054;
        }
    }

    .positions{
        .position-container{
            width: 75%;

            @media (max-width: 992px) {
                width: 100%;
            }
        }

        .position-tile{
            background-color: #F9FAFB;
            // height: 94px;
            padding: 18px;
            gap: 124px;
            border: 1px solid #EAECF0;
            border-radius: 8px;

            .title{
                color: #101828;
                font-family: "Open Sauce One";
                font-size: 18px;
                line-height: 30px;
                font-weight: 500;
            }

            .apply-text{
                color: #255F85;
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
                margin-top: 15px;
                margin-right: 5px;
            }
        }

    }
}

.more{
    background-color: #154A62;
    color: white;
    position: relative;
    overflow: hidden;

    .more-info{
        width: 50%;
        
        @media (max-width: 768px) {
            width: 100%;
        }

        h1{
            font-family: "Open Sauce One";
            font-size: 48px;
            line-height: 60px;
            font-weight: 700;
        }

        p{
            font-family: Inter;
            font-size: 20px;
            line-height: 32px;
            font-weight: 400;
        }

        button{
            padding: 16px 36px;
            gap: 8px;
            border-style: none;
            background: #FFFFFF;
            border-radius: 8px;
            color: #255F85;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
        }

    }

    .image{
        position: absolute;
        height: 450px;
        transform: scale(1.5, 1);
        right: 80px;
        bottom: -50px;
    }
}

.job-description {
    ul li{
        list-style: none;
    }
}