// Default variable overrides

$body-color: #111;
$primary: #1f456a;
$analogous: #f3f8fe;
$tertiary: #edeef2;
$max-body-width:1440px;
$header-height: 40px; 


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 2500px
);

$custom-colors: (
  "custom-color": #900
);


$theme-colors: (
  "primary": $primary,
  "analogous": $analogous,
  "tertiary": $tertiary
);

/*
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);
*/



// Required
@import "~bootstrap/scss/variables";

