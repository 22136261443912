.join-all{
    width: 100%;

    @media (min-width: 992px){
        width: 50%;
        transform: translateY(-20px);
    }
    
    h1{
        font-family: 'Open Sauce One'; 
        font-weight: 700;
        font-size: 48px;

        @media (max-width: 768px){
            font-size: 32px;
            line-height: 40px;
            // line-height: 24px;
        }
    }

    p{
        @media (max-width: 768px){
            font-size: 18px;
            // line-height: 24px;
        }
    }

}

.image{
    @media (max-width: 576px){
        width: 100%;
    }
}