.container-fluid{
    padding: 0 !important;
}
.app-wrapper {
    width: 80%;
    max-width: $max-body-width;
    height: inherit;
    margin: 0 auto;
    padding: 0 auto;
    @include media-breakpoint-down(sm){
        width: 90%;
    }
}
.clear-header{
    padding-top: $header-height + 30px;
}
html, body{
    justify-content:center;
    margin:0%;
    padding:0%;
}

.section{
    min-height: auto;
    align-items: center;
    padding-bottom: 3em;
    @include media-breakpoint-up(md){
        min-height: 99vh;
    }
    
}   

form {
    .form-label{
        font-weight: 500;
    }
}

.lead-title{
    font-family: 'Open Sauce One';
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: 'center';
    color: '#273043';
    @include media-breakpoint-down(md){
        font-size: 32px;
        line-height: 40px;
    }
}

@font-face {
    font-family: 'Inter';
    src: url('../../public/fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('../../public/fonts/OpenSauceOne-Medium.ttf');
}

.cursor-pointer{
    cursor: pointer;
}