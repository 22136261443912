input.custom-file-input{

    border-style: dashed;
    height: 60px;
    padding-left: 3.3rem;
    color: transparent; 
    padding-top: 1rem;
    &:hover , &:active, &:focus{
        color: transparent;
    }
    &::-webkit-file-upload-button{
        visibility: hidden;
    }
    &::file-selector-button{
        visibility: hidden;
    }
}
.file-input-wrapper
{
    position: relative;
    .file-text{
        top:30%;
        position: absolute;
        width: 100%;
        text-align: center;
        z-index: 0;
    }
}