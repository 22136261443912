.navbar{
    left:auto;
    right:auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    color: black;
    padding-top: 20px;
    transition-duration: 0.3s;
    
    .navbar-brand{
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        height: $header-height;
        overflow: hidden;

        svg {
            object-fit: cover;
            width: 175px;

            @media (max-width: 992px) {
                width: 136px;
            }
        }
    }

    .nav-link{
        color: black;
        font-family: Inter;
    }

    .nav-button{
        padding: 8px 20px;
        background-color: #255F85;
        border-style: none;
        border-radius: 8px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        margin-left: 40px;
        gap: 8px;
        font-family: Inter;
    }
}

.navbar-mobile{
    position: fixed;
    z-index: 10;
    display: block;
    background-color: #17161664;
    top:68px;
    width: 100%;
    height: 0px;
    transition-duration: 0.5s;
    

    // @keyframes show-animation {
    //     from {width: 0%;}
    //     to {width: 150px;}
    // }

    // @keyframes hide-animation {
    //     from {width: 150px;}
    //     to {width: 0%;}
    // }

    &.show{
        height: 100%;
    }

    &.hide{
        height: 0px;
    }


    .navbar-nav{
        background-color: #ffffff;
        height: 50%;
        width: 100%;
        padding-top: 20px;
        transition-duration: 0.5s;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: black;
    }
    
    .zero{
        opacity: 0;
        pointer-events: none;
    }

    .one{
        opacity: 1;
    }

    .close-btn{
        cursor: pointer;
        margin-bottom: 20px;
    }

    .nav-link{
        color: #101828;
        margin: 0px 0px 20px 0px;
    }

    .nav-button{
        padding: 8px 20px;
        background-color: #255F85;
        border-style: none;
        border-radius: 8px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        // margin-top: 10px;
        // margin-left: 40px;
        gap: 8px;
        font-family: Inter;
    }
}
